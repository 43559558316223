import React from "react";
import styled from "styled-components";
import ProjectGrid from "./ProjectGrid";
import SectionHeader from "./SectionHeader";

function Projects(props) {
  return (
    <WorksSection id="works">
      <IntroWrap>
        <div
          class="row section-header has-bottom-sep light-sep"
          data-aos="fade-up"
        >
          <SectionHeader
            name="Creative Process"
            title={["Check out the magic", <br />, "behind the scenes"]}
            primaryColor={false}
            headerType={"secondary"}
          />
        </div>
      </IntroWrap>

      <WorksContent>
        <ProjectGrid />
      </WorksContent>
    </WorksSection>
  );
}

export default Projects;

const WorksSection = styled.section`
  /* padding-top: 8rem;
  color: ${(props) => props.theme.darkRed};
  position: relative; */

  background-color: #0c0c0c;
  padding: 0 0 5rem 0;
  min-height: 800px;
  position: relative;
`;

const IntroWrap = styled.div`
  padding: 15rem 0 10rem;
  background: #111111;
`;

const WorksContent = styled.div`
  /* max-width: 1200px; */
  width: 100%;
  margin-top: -10rem;
`;
