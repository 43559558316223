import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import styled from "styled-components";
import Img1 from "../images/PhotoGallery/1.jpg";
import Img2 from "../images/PhotoGallery/2.jpg";
import Img3 from "../images/PhotoGallery/3.jpg";
import Img4 from "../images/PhotoGallery/4.jpg";
import Img5 from "../images/PhotoGallery/5.jpg";
import Img6 from "../images/PhotoGallery/6.jpg";

const mediaImgs = [Img1, Img2, Img3, Img4, Img5, Img6];

const ProjectGrid = () => (
  <Content>
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 900: 2 }}>
      <Masonry columnsCount={2} gutter="10px">
        <VideoContainer>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/jGJ6P0dTRJM"
            title="Running Out of Time"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="true"
          ></iframe>
        </VideoContainer>
        <VideoContainer>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/L0C00PsostY"
            title="Set Me Free"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="true"
          ></iframe>
        </VideoContainer>
      </Masonry>
    </ResponsiveMasonry>
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 600: 2, 900: 3 }}>
      <Masonry columnsCount={3} gutter="10px">
        {mediaImgs.map((image, i) => (
          <img
            key={i}
            src={image}
            alt=""
            style={{ width: "100%", display: "block" }}
          />
        ))}
      </Masonry>
    </ResponsiveMasonry>
  </Content>
);

export default ProjectGrid;

const Content = styled.div`
  max-width: 60%;
  margin: 0 auto 0 auto;

  @media only screen and (max-width: 1200px) {
    max-width: 70%;
  }

  @media only screen and (max-width: 800px) {
    max-width: 80%;
  }

  @media only screen and (max-width: 500px) {
    max-width: 90%;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: 10px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const PlayerContainer = styled.div`
  position: relative;
  padding-bottom: 100%; /* 16:9 */
  /* height: 0; */
  margin-bottom: 10px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
