import React from "react";
import styled from "styled-components";
import HeroBG from "../images/hero-bg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faSpotify,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-scroll";
import { Parallax } from "react-parallax";

function Hero() {
  return (
    <div id="home">
      <HeroContainer bgImage={HeroBG} strength={500}>
        <HomeOverlay />
        <HomeShadowOverlay />
        <HomeContent>
          <HomeContentMain>
            <h3>Hello</h3>
            <h1>
              I'm <span>Valeria Flores</span> <br />
              {/* B.S. of Music Production & Engineering <br /> */}
              songwriter, composer <br />
              and singer. <br />
            </h1>
            <HomeContentButtons hidden>
              <HomeButton href="#contact">Start a Project</HomeButton>
              <HomeButton href="#about">Resume</HomeButton>
            </HomeContentButtons>
          </HomeContentMain>
          <HomeContentScroll>
            <Link to="singles" spy={true} smooth={true} duration={500}>
              <span>Scroll Down</span>
            </Link>
          </HomeContentScroll>
          <HomeContentLine />
        </HomeContent>

        <HomeContentSocial>
          <li>
            <a href="https://www.facebook.com/valfloresgza" target="_blank">
              <FaIcon icon={faFacebook} />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/valfloresg" target="_blank">
              <FaIcon icon={faInstagram} />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/valfloresg" target="_blank">
              <FaIcon icon={faTwitter} />
            </a>
          </li>
          <li>
            <a
              href="https://open.spotify.com/artist/41pa33Iw5hehbAv5EP3xiV?si=GlyicV98QLGHFXDlqCuK-w"
              target="_blank"
            >
              <FaIcon icon={faSpotify} />
            </a>
          </li>
        </HomeContentSocial>
      </HeroContainer>
    </div>
  );
}

export default Hero;

const HeroContainer = styled(Parallax)`
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 786px;
  background-color: transparent;
  position: relative;
  display: table;

  @media only screen and (max-width: 500px) {
    min-height: 642px;
  }

  @media only screen and (max-width: 400px) {
    min-height: 630px;
  }
`;

const HomeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: #000000;
`;

const HomeShadowOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: -moz-linear-gradient(
    top,
    transparent 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    top,
    transparent 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
`;

const HomeContent = styled.div`
  /* display: table-cell; */
  width: 100%;
  height: 100%;
  vertical-align: middle;
  height: calc(100vh - 10rem);
  padding-bottom: 10rem;
  position: relative;
  overflow: hidden;

  span {
    color: ${(props) => props.theme.darkRed};
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  h3 {
    font-family: "montserrat-medium", sans-serif;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    position: relative;
    margin-top: 0;
    margin-bottom: 1.2rem;
    margin-left: 6px;

    @media only screen and (max-width: 800px) {
      font-size: 1rem;
    }
  }

  h1 {
    font-family: "montserrat-medium", sans-serif;
    font-weight: 500;
    font-size: 4.5rem;
    line-height: 1.333;
    margin-top: 0;
    color: #ffffff;

    @media only screen and (max-width: 1500px) {
      font-size: 4rem;
    }

    @media only screen and (max-width: 1200px) {
      font-size: 3rem;
    }

    @media only screen and (max-width: 1000px) {
      font-size: 2.8rem;
    }

    @media only screen and (max-width: 800px) {
      font-size: 2.5rem;
    }

    @media only screen and (max-width: 600px) {
      font-size: 2.2rem;
    }

    @media only screen and (max-width: 500px) {
      font-size: 2rem;
    }
  }
`;

const HomeContentMain = styled.div`
  margin-left: 10%;
  margin-right: 10%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  @media only screen and (max-width: 1500px) {
    max-width: 1000px;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 800px;
  }

  @media only screen and (max-width: 1000px) {
    padding-right: 80px;
    max-width: 680px;

    br {
      display: none;
    }
  }

  @media only screen and (max-width: 800px) {
    padding-right: 80px;
    max-width: 550px;
  }

  @media only screen and (max-width: 700px) {
    max-width: 490px;
  }

  @media only screen and (max-width: 500px) {
    text-align: center;
    padding-left: 5px;
    padding-right: 25px;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  @media only screen and (max-width: 400px) {
    width: auto;
  }
`;

const HomeButton = styled.a`
  margin-bottom: 1.2rem;
  text-decoration: none;
  padding: 20px 20px;
  font-family: "montserrat-semibold", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 5px;

  /* btn--stroke */
  background: transparent !important;
  border: 0.2rem solid #fff;
  color: #fff;

  &:hover,
  &:hover {
    /* border: 0.2rem solid #000000; */
    color: #000000;
  }

  @media only screen and (max-width: 700px) {
    padding: 15px 20px;
  }
`;

const HomeContentButtons = styled.div`
  margin-top: 4.5rem;
  padding: 1rem;
  text-align: left;

  ${HomeButton} {
    width: 215px;
    margin: 1.5rem 1.5rem 0 0;
    letter-spacing: 0.25rem;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      background: #ffffff !important;
      color: #000000;
    }

    @media only screen and (max-width: 900px) {
      display: block;
      margin-top: 2rem;
    }

    @media only screen and (max-width: 700px) {
      width: 60%;
      margin: 1.5rem 0 0 0;
    }
    @media only screen and (max-width: 500px) {
      width: auto;
      margin: 1.5rem 0 0 0;
    }
  }

  @media only screen and (max-width: 900px) {
    margin-top: 4rem;
  }

  @media only screen and (max-width: 700px) {
    padding-top: 0.8rem;
    margin-top: 2rem;
    text-align: center;
  }

  @media only screen and (max-width: 500px) {
    padding-top: 0.6rem;
  }
`;

const HomeContentScroll = styled.div`
  position: absolute;
  right: 9.5rem;
  bottom: 5rem;

  a {
    font-family: "montserrat-semibold", sans-serif;
    font-size: 0.8rem;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    color: #ffffff;
    position: relative;
    display: inline-block;
    line-height: 3rem;
    padding-left: 2.5rem;

    &::before {
      border-bottom: 2px solid ${(props) => props.theme.titleColor};
      border-right: 2px solid ${(props) => props.theme.titleColor};
      content: "";
      display: block;
      height: 8px;
      width: 8px;
      margin-top: -6px;
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform-origin: 66% 66%;
      -ms-transform-origin: 66% 66%;
      transform-origin: 66% 66%;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
    }
  }

  @media only screen and (max-width: 700px) {
    right: 7.5rem;
  }

  @media only screen and (max-width: 600px) {
    bottom: 5.4rem;
  }
`;

const HomeContentLine = styled.div`
  display: block;
  width: 2px;
  height: 8rem;
  background-color: ${(props) => props.theme.titleColor};
  position: absolute;
  right: 7.2rem;
  bottom: 0;

  @media only screen and (max-width: 700px) {
    right: 5.5rem;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const HomeContentSocial = styled.ul`
  font-family: "montserrat-semibold", sans-serif;
  list-style: none;
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 50%;
  right: 5.4rem;
  -webkit-transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);

  a {
    color: white;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  li {
    position: relative;
    padding: 0.9rem 0;

    &:hover {
      span {
        opacity: 1;
        visibility: visible;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
      }
    }

    a {
      display: block;
      width: 33px;
      height: 33px;
    }
  }

  span {
    position: absolute;
    top: 0;
    line-height: 33px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  span {
    color: ${(props) => props.theme.titleColor};
    right: 3rem;
    font-size: 1rem;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }

  @media only screen and (max-width: 600px) {
    right: 2rem;

    li {
      padding: 0.6rem 0;
    }

    span {
      display: none;
    }
  }

  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

const FaIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  line-height: 33px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  font-size: 14px;
  right: 0;
  text-align: center;
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: calc(03px - 4px);
  /* border: 2px solid #ffffff; */
  /* border-radius: 50%; */
  padding: 5px;

  @media only screen and (max-width: 600px) {
    font-size: 17px;
    border: none;
  }
`;
