import * as React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import About from '../components/About'
import Projects from '../components/Projects'
import Singles from '../components/Singles'
// import { StaticImage } from 'gatsby-plugin-image'

const IndexPage = ({ location, data }) => {

  return (
    <Layout location={location} pageTitle="Home Page">
      {/* <Seo title="Home" /> */}
      <Hero />
      {/* <About /> */}
      <Singles />
      <Projects />
      {/* <StaticImage
        alt="Clifford, a reddish-brown pitbull, posing on a couch and looking stoically at the camera"
        src="https://pbs.twimg.com/media/E1oMV3QVgAIr1NT?format=jpg&name=large"
      /> */}
    </Layout>
  )
}

export default IndexPage