import React from "react";
import styled from "styled-components";

function Stats(props) {
  return (
    <StatsCard>
      <Count>{props.number}</Count>
      <h5>{props.title}</h5>
    </StatsCard>
  );
}

export default Stats;

const StatsCard = styled.div`
  text-align: center;
  margin: 4.5rem 0;
  padding: 0 2rem;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  /* border-right: 1px solid rgba(255, 255, 255, 0.25); */

  @media only screen and (max-width: 1200px) {
    margin: 1.5rem 0;
  }

  @media only screen and (max-width: 600px) {
    margin: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative;
    border: none;

    /* &::after {
      content: "";
      display: block;
      height: 1px;
      width: 250px;
      background-color: rgba(255, 255, 255, 0.25);
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -125px;
    } */
  }

  h5 {
    font-family: "montserrat-semibold", sans-serif;
    margin-top: 1.8rem;
    color: red;
    font-size: 1.5rem;
    line-height: 1.333;
    position: relative;

    @media only screen and (max-width: 800px) {
      margin-top: 1rem;
    }
  }
`;

const Count = styled.div`
  font-family: "montserrat-bold", sans-serif;
  font-size: 6rem;
  font-weight: 800;
  line-height: 1;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media only screen and (max-width: 800px) {
    font-size: 5.5rem;
  }

  @media only screen and (max-width: 700px) {
    font-size: 5rem;
  }

  @media only screen and (max-width: 600px) {
    margin: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: relative;
  }
`;
