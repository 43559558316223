import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Irreplaceable from "../images/Singles/Irreplaceable.jpg";
import Autumn from "../images/Singles/Autumn.jpg";
import RunningOutOfTime from "../images/Singles/RunningOutOfTime.jpg";
import SetMeFree from "../images/Singles/SetMeFree.jpg";
import Polaroid from "../images/Singles/polaroid2.jpg";
import SectionHeader from "./SectionHeader";

function Singles() {
  return (
    <SinglesSection id="singles">
      <SectionHeader
        name="Listen my debut EP..."
        title={"Runing Out of Time"}
        primaryColor={false}
        headerType={"secondary"}
      />

      <Box sx={{ flexGrow: 1, marginLeft: "5%", marginRight: "5%" }}>
        <PlayerContainer>
          <iframe
            width="250"
            height="0%"
            src="https://open.spotify.com/embed/album/4Hk9t6acvhrcpx6kPm2ms2?utm_source=generator"
            title="Set Me Free"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            allowfullscreen="true"
          ></iframe>
        </PlayerContainer>
        <SinglesGrid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={4}
        >
          {itemData.map((item) => (
            <Grid item xs={6} sm={6} md={2}>
              <Img alt={item.title} src={item.img} loading="lazy" />
            </Grid>
          ))}
        </SinglesGrid>
      </Box>
    </SinglesSection>
  );
}

export default Singles;

const SinglesSection = styled.section`
  padding-top: 5rem;
  padding-bottom: 3rem;
  background-color: #0c0c0c;
  color: ${(props) => props.theme.darkRed};
  position: relative;
`;

const PlayerContainer = styled.div`
  position: relative;
  padding-bottom: 200px;
  height: 0;
  //margin-bottom: 10px;
  margin: 10px;

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 250px;
  }
`;

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  borderRadius: "5px"
});

const SinglesGrid = styled(Grid)`
  position: relative;
  top: 50px;
`;

const itemData = [
  {
    img: RunningOutOfTime,
    title: "Running Out of Time"
  },
  {
    img: SetMeFree,
    title: "Set Me Free"
  },
  {
    img: Irreplaceable,
    title: "Irreplaceable"
  },
  {
    img: Autumn,
    title: "Autumn"
  },
  {
    img: Polaroid,
    title: "Mercy..."
  }
];
