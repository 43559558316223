import React from "react";
import styled from "styled-components";

function SectionHeader(props) {
  return (
    <Header>
      <SubHeader
        primaryColor={props.primaryColor}
        headerType={props.headerType}
      >
        {props.name}
      </SubHeader>
      <Display primaryColor={props.primaryColor} headerType={props.headerType}>
        {props.title}
      </Display>
    </Header>
  );
}

export default SectionHeader;

const Header = styled.div`
  text-align: center;
  position: relative;
  margin-bottom: 3.6rem;
  margin-left: auto;
  margin-right: auto;
  /* padding-bottom: 2.1rem; */

  &::before {
    content: "";
    display: inline-block;
    height: 1px;
    width: 550px;
    background-color: rgba(255, 255, 255, 0.15);
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  @media only screen and (max-width: 1200px) {
    max-width: 850px;
  }

  @media only screen and (max-width: 900px) {
    max-width: 750px;
  }

  @media only screen and (max-width: 800px) {
    width: 650px;
  }

  @media only screen and (max-width: 600px) {
    width: 450px;

    &::before {
      width: 250px;
    }
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
  }
`;

const SubHeader = styled.h3`
  font-family: "montserrat-bold", sans-serif;
  font-size: 1.2rem;
  line-height: 1.333;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  color: ${(props) =>
    props.primaryColor ? props.theme.white : props.theme.darkRed};
  margin-top: 0;
  margin-bottom: 0;

  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;

const Display = styled.h1`
  font-family: "montserrat-medium", sans-serif;
  font-weight: 500;
  font-size: ${(props) => (props.headerType === "primary" ? "60px" : "45px")};
  color: ${(props) =>
    props.primaryColor ? props.theme.darkRed : props.theme.white};
  line-height: 50px;
  margin: 40px 0;
  padding: 0 0 40px 0;

  @media only screen and (max-width: 1200px) {
    font-size: ${(props) => (props.headerType === "primary" ? "60px" : "30px")};
  }

  @media only screen and (max-width: 800px) {
  }

  @media only screen and (max-width: 600px) {
    padding: 0 0 20px 0;
  }

  @media only screen and (max-width: 400px) {
    font-size: ${(props) => (props.headerType === "primary" ? "60px" : "20px")};
  }
`;
