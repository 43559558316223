import React from "react";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import Stats from "./Stats";
import SectionHeader from "./SectionHeader";

function About() {
  return (
    <AboutSection id="about">
      <SectionHeader
        name="Hello"
        title="I'm Val"
        primaryColor={true}
        headerType="primary"
      />

      <AboutDesc>
        <p>Songwriter, Music Production & Engineering</p>
      </AboutDesc>

      <StatsGrid
        container
        rowSpacing={0}
        columnSpacing={0}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Stats number={"9"} title={"Awards Received"} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Stats number={"1505"} title={"Hrs Played"} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Stats number={"1676"} title={"Followers"} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Stats number={"5"} title={"Releases"} />
        </Grid>
      </StatsGrid>

      {/* <ProjectGrid /> */}

      <AboutLine />
    </AboutSection>
  );
}

export default About;

const AboutSection = styled.section`
  padding-top: 8rem;
  padding-bottom: 5rem;
  background-color: "#0c0c0c";
  color: ${(props) => props.theme.darkRed};
  position: relative;
`;

const AboutDesc = styled.div`
  font-family: "montserrat-semibold", sans-serif;
  margin: auto 15% auto 15%;
  font-size: 2rem;
  line-height: 1.75;
  text-align: center;
  max-width: 1024px;
  padding-bottom: 2rem;

  @media only screen and (max-width: 1200px) {
    font-size: 1.5rem;
    max-width: 940px;
  }

  @media only screen and (max-width: 800px) {
    font-size: 1.2rem;
    max-width: 600px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;

const StatsGrid = styled(Grid)`
  padding-left: 5%;
  padding-right: 5%;
`;

const AboutLine = styled.div`
  display: block;
  width: 2px;
  height: 8rem;
  background-color: #ffffff;
  position: absolute;
  right: 7.2rem;
  top: 0;

  @media only screen and (max-width: 700px) {
    right: 5.5rem;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
